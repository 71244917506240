<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 95px;
            right: 42px;
            position: fixed;
            text-align: right;">
        <i @click="handleSelect(gobackurl)" class="el-icon-close"></i>
      </div>
      <div class="pt20"></div>
      <div>
      <div class="jiben fle">
       <div style="display: flex;" class="bgl"> <h4 style="width: 115px;margin: 0;line-height: 28px;">反馈者信息</h4><h6 style="margin: 0;">共{{form.report_user.length}}人</h6>
       <el-button class="mj" @click="shouqi" v-show="btnshow" size="mini" type="info">展开</el-button>
       <el-button class="mj" @click="shouqi" v-show="!btnshow" size="mini" type="info">收起</el-button>
        </div>
        <div  class="fle" v-show="!btnshow">
          <div style="display: flex;" v-for="(user,index) in form.report_user" :key="user.id">
            <div>反馈{{index+1}}</div>
            <div>手机号：<input type="text" disabled v-model="user.phone"></div>  
            <div class="lef">用户编号：<input type="text" disabled v-model="user.user.uid"></div> 
            <div class="lef">提交时间：<input type="text" disabled v-model="user.created_at"></div> 
            <div class="lef">反馈问题：<input v-if="user.report_type" type="text" disabled v-model="user.report_type.name"></div> 
          </div>
        </div>
        <div style="display: flex;" class="bgl">
          <h4 style="margin: 0;line-height: 28px;">反馈订单信息</h4>
        </div>
        <div style="display: flex;">
          <div v-if="form.status==0">处理状态：<input type="text" disabled value="未处理"></div>
          <div v-if="form.status==1">处理状态：<input type="text" disabled value="反馈成功"></div>
          <div v-if="form.status==2">处理状态：<input type="text" disabled value="反馈失败"></div>
          <div>反馈订单编号：<input type="text" disabled v-model="form.report_no"></div>
          <div v-if="form.type==1">对象类型：<input type="text" disabled value="内容"></div>
          <div v-else>对象类型：<input type="text" disabled value="评论"></div>
          
          
        </div>

          <h4 class="bgl">反馈对象信息</h4>
          <div v-if="type==2">
          <div style="display: flex;">
            <h4>基本信息</h4>
            <el-button class="mj" @click="shouqi2" v-show="btnshow2" size="mini" type="info">展开</el-button>
            <el-button class="mj" @click="shouqi2" v-show="!btnshow2" size="mini" type="info">收起</el-button>
          </div>
            <div v-show="!btnshow2" style="display: flex;">
              <div>账户手机号：<input v-if="form.examine" :title="form.examine.phone" type="text" disabled v-model="form.examine.phone"></div>
              <div>用户编号：<input v-if="form.examine" :title="form.examine.uid" type="text" disabled v-model="form.examine.uid"></div>
              <div>提交时间：<input v-if="form.examine" :title="form.examine.created_at" type="text" disabled v-model="form.examine.created_at"></div>
              <div>审核订单编号：<input v-if="form.examine" :title="form.examine.no" style="width:200px;" type="text" disabled v-model="form.examine.no"></div>
            </div>
          </div>
          <div style="display: flex;">
            <div v-if='type==1'>审核订单编号：<input v-if="form.article.examine" :title="form.article.examine.no" style="width:220px;" type="text" disabled v-model="form.article.examine.no"> </div> 
            <div v-if='type==1'>是否与投诉联动：<input v-if="form.article.examine && form.article.examine.order_no" style="width:50px;"  type="text" disabled value="是"><input v-else style="width:50px;" type="text" disabled value="否"> </div>
          </div>
            <div v-if='type==1'>
            <h4 class="bgl">待审核内容</h4>
            <div>标题：<input type="text" :title="form.article.title" style="width:470px;" disabled v-model="form.article.title"></div> 
            <div>话题：<input type="text" :title="form.article.topic.name" style="width:470px;" disabled v-model="form.article.topic.name"></div>
            <div>定位：<input type="text" :title="form.article.location" style="width:470px;" disabled v-model="form.article.location"></div>
            
                <div>图片：</div>
                <div>
                <el-image v-for="images in form.article.images" :key="images.id"
                style="width: 100px; height: 100px"
                :src="images.th_images_url" 
                :preview-src-list="[images.images_url]">
                </el-image>
                </div>
                <div>正文：</div>
                <div><textarea type="text" style="width:500px;min-height:100px;" disabled v-model="form.article.content"></textarea></div>
             </div>
        <div v-if="type==2">
            <div style="display: flex;">
                <h4>针对对象信息</h4>
          <el-button class="mj" @click="shouqi3" v-show="btnshow3" size="mini" type="info">展开</el-button>
          <el-button class="mj" @click="shouqi3" v-show="!btnshow3" size="mini" type="info">收起</el-button></div>
           <div v-show="!btnshow3">
            <div>类型：<input type="text" disabled value="评论"></div>
            <div>内容：<textarea type="text" style="width:500px;min-height:100px;" disabled v-model="form.content"></textarea></div>
            <h4>待审核内容</h4>
            <div>正文：<textarea type="text" style="width:500px;min-height:100px;" disabled v-model="form.examine_content"></textarea></div>
            </div>
        </div>
        </div>
    <div class="form">
      <h4 class="bgl" style="text-align: left;">反馈审核处理信息</h4>
      <el-form ref="form" :model="form" label-width="180px">
      
        <el-form-item  :required="true" label="审核结果">
          <el-select  :disabled='form.status == 1 || look'
             v-model="form.status2" placeholder="请选择">
            <el-option
              v-for="item in opstatus"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item :required="true" v-if="form.status2 == 3" label="违规程度">
          <el-select :disabled='form.status == 1'  @change="refreshStatus2" v-model="form.violation2" placeholder="请选择">
            <el-option 
              v-for="item in opviolation"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :required="false" v-if="form.status2 == 3" label="未通过的原因">
          <el-checkbox  v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          
          <div v-if="form.status == 1 && form.type == 1">
           <el-checkbox checked disabled v-for="reason in form.article.examine.reason" :key="reason.id" :label="reason" >{{reason.name}}</el-checkbox>
          </div>
          <div v-if="form.status == 1 && form.type == 2">
           <el-checkbox checked disabled v-for="reason in form.examine.reason" :key="reason.id" :label="reason" >{{reason.name}}</el-checkbox>
          </div>
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="city in cities" :label="city" :key="city.id">{{city.name}}</el-checkbox>
            <el-input style="    width: 600px;" v-model="zidingyi" maxlength="12" placeholder="最多输入12个字"></el-input>
          </el-checkbox-group>
          
        </el-form-item>

        <el-form-item :required="false" label="备注">
          <el-input
            style="max-width: 600px;"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10}"
            placeholder="请输入内容"
            v-model="form.remark">
          </el-input>
        </el-form-item>
        <div style="margin-left: 100px;">处理结果：{{form.status2==3?'反馈成功':''}}{{form.status2==2?'反馈失败':''}}</div>
        
      <el-form-item>
        <el-button type="primary" v-if="form.status == 0&&!look" style="margin-left: 45%;margin-top: 20px;" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
</div>
</div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'

 
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
        checkAll: false,
        checkedCities: [],
        cities: [],
        isIndeterminate: true,
      drawer: false,
      gobackurl:'',
      id:0,
      type:1,
      look:false,
      checkeds:false,
      form: {
        report_user:[],
        status:'',
        status2:'',
        article:{
          content:'',
          id:'',
          images:[{images_url:''}],
          is_recommend:0,
          label:[],
          title:'',
          topic_id:'',
          topic:{
            id:'',
            topic_examine:'',
            name:'',
          },
        }
        },
        options:[],
        opstatus:[
          {
            value: 2,
            label: '审核通过'
          },
          {
            value: 3,
            label: '审核未通过'
          }
        ],
        opviolation:[
          {
            value: '1',
            label: '轻度'
          },
          {
            value: '2',
            label: '重度'
          },
          {
            value: '3',
            label: '删除'
          }
        ],
        zidingyi:'',
        btnshow:false,
        btnshow2:false,
        btnshow3:false
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
      refreshStatus2(val){
        if(this.type == 1){
          if(val == 1 || val == 2){
            this.cities = [{id:1,name:'含有敏感词'},{id:3,name:'包含广告'},{id:5,name:'图片违规'},{id:7,name:'标题违规'},{id:9,name:'正文违规'},{id:4,name:'自定义'}];
          }else if(val == 3){
            this.cities = [{id:10,name:'分类与话题不符'},{id:11,name:'分类与内容不符'},{id:12,name:'图片与内容无关'},{id:4,name:'自定义'}];
          }
        }else{
          this.cities = [{id:1,name:'含有敏感词'},{id:2,name:'涉及恶意攻击'},{id:3,name:'包含广告'},{id:4,name:'自定义'}]
        }
        this.$forceUpdate()
      },
      handleCheckAllChange(val) {
          if(this.type == 2){
        this.checkedCities = val ? this.cities : [];
          }else{
        this.checkedCities = val ? this.cities : [];
          }
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },
     onSubmit() {
      let _this = this;
      let postdata = {};
      this.form.id = this.id;
      let cities = this.checkedCities;
        for(let i in cities){
          if(cities[i].name == '自定义'){
            cities[i].name = _this.zidingyi;
          }
        }
      this.form.reason = cities;
      if(_this.form.status2 == 3){
        if(!_this.form.violation2){
            _this.$message.error('违规程度必选');
            return;
        }
        if(_this.form.reason.length == 0){
          _this.$message.error('未通过的原因必选');
            return;
        }
        postdata.violation = _this.form.violation2;
        postdata.reason = JSON.stringify(_this.form.reason);
      }else{
        postdata.reason = '[]';
        postdata.violation = 0;
      }
        if(_this.form.remark){
          postdata.remark = _this.form.remark;
        }else{
          postdata.remark = '';
        }
        if(!_this.form.status2){
            _this.$message.error('请填写必填项');
            return;
        }
        postdata.status = _this.form.status2;
        postdata.report_no = _this.form.report_no;
        postdata.updated_at = _this.form.updated_at;
        if(this.type == 2){
postdata.examine_updated_at = _this.form.examine?_this.form.examine.updated_at:'';
        
        }else{
postdata.examine_updated_at = _this.form.article?_this.form.article.examine?_this.form.article.examine.updated_at:'':'';
        
        }
        

      axios.post(config.report,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav(_this.gobackurl);
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
      shouqi(){
        this.btnshow = !this.btnshow;
      },
      shouqi2(){
        this.btnshow2 = !this.btnshow2;
      },
      shouqi3(){
        this.btnshow3 = !this.btnshow3;
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        this.type = obj.type;
        this.look = obj.look;
        axios.get(config.reportInfo,{params:{report_no:obj.report_no}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                if(_this.look && _this.form.status != 0){
                  _this.form.remark = _this.form.examine?_this.form.examine.remark : _this.form.article.examine.remark;
                  _this.form.status2 = _this.form.status;
                  _this.form.violation2 = _this.form.examine?_this.form.examine.violation:_this.form.article.examine.violation;
                }
                
                if(_this.form.status == 1){
                  _this.form.status2 = 3
                }
                if(_this.form.type == 1){//内容
  _this.form.violation2 = _this.form.article.examine.violation ? _this.form.article.examine.violation+'':'';//违规程度赋值
                }else{//评论
  _this.form.violation2 = _this.form.examine.violation ? _this.form.examine.violation+'':'';//违规程度赋值
                }
                

            }else{
                _this.$message.error(response.data.message);
                if(sessionStorage.getItem('state') == 1){
                _this.$router.push('/feedback/feedbacklist');
                }else{
                _this.$router.push('/feedback/feedbacklist2');
                }
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
      if(sessionStorage.getItem('state') == 1){
        this.gobackurl = '/feedback/feedbacklist';
      }else{
        this.gobackurl = '/feedback/feedbacklist2';
      }
  }
}
</script>
<style scoped>
.el-form {
    text-align: left;
}
.jiben{
    text-align: left;
}
.jiben div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
.fle div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 5px 10px 5px 0;
    box-sizing: border-box;
    font-weight: bold;
}
.flex{
  display: flex;
}
.le{
  margin-left: 50px;
}
.mj{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
}
.ww{
  margin-left: 10px;
}
.lef{
    margin-left: 20px;
}
.dageda{
    display: flex;
}

.el-icon-close:before{
  font-size: 24px;
}
.jiben .bgl{
    background-color: aliceblue;
    padding: 10px;
    margin-bottom: 5px;
    margin-top: 0;
}.bgl{
    background-color: aliceblue;
    padding: 10px;
    margin-bottom: 5px;
    margin-top: 0;
}
</style>
